<template>
  <!-- TODO - figure out if having FAQ schema on a page with other schema is okay (looks good with testing)-->
  <!-- https://www.searchenginejournal.com/google-on-using-multiple-types-of-schema-markup-on-same-page/412457/#close -->
  <div class="faq-wrapper" itemscope itemtype="https://schema.org/FAQPage">
    <div class="faq-content">
      <h3 class="h2 faq-title">
        {{ title }}
      </h3>
      <div
        v-for="(question, index) in items"
        :key="index + '_question'"
        class="flex list-container"
        :class="index >= showNumber ? 'hidden' : ''"
        itemscope
        itemprop="mainEntity"
        itemtype="https://schema.org/Question"
      >
        <button
          :class="['section-header', openIndexes[index] ? 'open' : 'closed']"
          :aria-expanded="openIndexes[index]"
          :aria-label="openIndexes[index] ? 'Hide Answer' : 'Show Answer'"
          itemprop="name"
          @click="toggleOpen(index)"
        >
          {{ question.faq_title }}
        </button>
        <div class="section-list-container" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="answer" itemprop="text" v-html="question.text" />
        </div>
      </div>
      <CustomButton
        v-if="items && items?.length > baseNumber"
        style-color="black"
        style-name="secondary"
        class="show-more"
        @click-button="toggleShowNumber"
        >{{ buttonText }}</CustomButton
      >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="warning && localeStore?.isUsLocale" class="warning" v-html="warning" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLocaleStore } from '~/store/locale';
const localeStore = useLocaleStore();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  // eslint-disable-next-line vue/prop-name-casing
  accordion_items: {
    type: Array as () => any[],
    required: true,
  },
  warning: {
    type: String,
    required: false,
    default: '',
  },
});

const baseNumber = 5;

const showNumber = ref(baseNumber);
const openIndexes = ref(new Array(props.accordion_items?.length).fill(false));

const items = computed<any[] | undefined>(() => props.accordion_items);
const buttonText = computed<string>(() => (showNumber.value === baseNumber ? 'View More' : 'View Less'));

function toggleShowNumber() {
  if (showNumber.value === baseNumber) {
    showNumber.value = items.value?.length || baseNumber;
  } else {
    showNumber.value = 5;
  }
}

function toggleOpen(i: number) {
  // Close other open indexes
  openIndexes.value = openIndexes.value.map((item, idx) => {
    if (idx !== i) {
      return false;
    }
    return item;
  });
  openIndexes.value[i] = !openIndexes.value[i];
}
</script>

<style lang="scss" scoped>
$content-transition: all 0.4s ease-out;
$icon-transition: transform 0.4s ease-out;

.faq-wrapper {
  // background-color: $color-neutral-warm-50;
  color: $color-neutral-black;
  padding: 1rem 0;
  .faq-content {
    max-width: 80rem;
    margin: 0 auto;
  }
  .faq-title {
    margin-bottom: 1.5rem;
  }
  button:not(.show-more) {
    color: $color-neutral-cool-900;
    font-size: 1.125rem;
    line-height: 125%;
    background: none;
    padding: 0;
    border: none;
  }
  .show-more {
    margin-top: 1rem;
    text-transform: none;
    width: auto;
  }
  .section-header {
    align-items: center;
    font-weight: 500;
    text-align: left;
    display: flex;
    cursor: pointer;

    + .section-list-container {
      transition: $content-transition;
    }

    &::after {
      content: '';
      transform: rotate(0deg);
      transition: $icon-transition;
      display: block;
      margin-left: auto;
      min-width: 35px;
      @include local-mixins.backgroundMaskImage(
        local-functions.svg-img-source('arrow-down-s-line.svg'),
        $color-neutral-black,
        35px,
        35px
      );
    }

    &.open {
      + .section-list-container {
        overflow: visible;
        max-height: 500px;
      }
      &::after {
        transform: rotate(-180deg);
        transition: $icon-transition;
      }
    }
    &:focus {
      outline: none;
      border-radius: 4px;
    }
  }

  .section-list-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 0;
    transition: $content-transition;

    .answer {
      margin-top: 20px;
      font-size: 0.875rem;
      line-height: #{local-functions.rem-calc(21)};
    }
  }

  .list-container {
    margin: 0;
    border-top: 1px solid $color-neutral-cool-200;
    border-bottom: 1px solid $color-neutral-cool-200;
    padding: 18px 0;
    flex-direction: column;

    &.hidden {
      display: none;
    }

    + .list-container {
      border-top: none;
    }
  }

  .warning,
  .warning p a {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: #{local-functions.rem-calc(48 0)};
  }
}

@include local-mixins.tablet_and_mobile {
  .faq-wrapper {
    padding: 1rem;
  }
}
</style>
